import React from 'react';
import { motion } from 'framer-motion';
import './Contact.css';




function Contact() {
  return (
    <div className="contact">
      <div className="container">
           {/* Call to Action Section */}
      <section className="call-to-action mt-5 text-center">
      <h2>Ready for an Unforgettable Adventure?</h2>
      <p>Book your tour with us today and explore the wonders of Africa.</p>
      <a href="https://wa.me/254712603487?text=Hi, I got your WhatsApp information from your website." className="btn btn-success btn-lg" role="button">Send Whatsapp Message</a>
      </section>

        <div className="row align-items-center">
          <div className="col-md-6 primary-bg">
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h1>Contact Us</h1>
                <p>Landsville Court, Westlands Road.</p>
            <p>Nairobi, Kenya</p>
            <p className="mt-3">
              <strong>Phone:</strong> 
              <span>
                <a href="tel:+254712603487" className="text-blue-500 hover:underline">
                  +254 712 603487
                </a>
              </span>
            </p>

            {/* <p className="mt-3"><strong>Phone:</strong> <span>+254 7147 45023</span></p> */}
            <p><strong>Email:</strong> <span>info@africanuncut-safaris.com</span></p>
            <p><strong>Email:</strong> <span>ben@africanuncut-safaris.com</span></p>
        

              </motion.div>
          </div>

          <div className="col-md-6">
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h2>Get in Touch</h2>
              <form className="contact-form">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input type="text" id="name" name="name" required />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <textarea id="message" name="message" required></textarea>
                </div>
                <button type="submit">Submit</button>
              </form>
            </motion.div>
          </div>
        </div>
        
        </div>
          
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3988.8399546538726!2d36.807761000000006!3d-1.26888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMcKwMTYnMDguMCJTIDM2wrA0OCcyNy45IkU!5e0!3m2!1sen!2ske!4v1722369985369!5m2!1sen!2ske"
                width="1500"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </motion.div>
        
      

      
    </div>
  );
}

export default Contact;
