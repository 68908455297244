/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './Footer.css';
import {NavLink } from 'react-router-dom'
import img11 from '../assets/img/greenlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTiktok, faWhatsapp,faInstagram } from '@fortawesome/free-brands-svg-icons';



function Footer() {
  return (


<footer id="footer" className="footer">
  <div className="container footer-top">
    <div className="row gy-4">
      <div className="col-lg-3 col-md-6 footer-about">
        <NavLink to="/" className="d-flex align-items-center">
          {/* Optional logo or brand name */}
        </NavLink>
        <div className="footer-contact pt-3">
          <p>Landsville Court, Westlands Road.</p>
          <p>Nairobi, Kenya</p>
          <p className="mt-3"> <strong> Phone:</strong> 
                <span>
          <a href="tel:+254712603487" className="text-white-500 hover:underline">
            +254 712 603487
          </a>
        </span>             
        </p>

          {/* <p className="mt-3"><strong>Phone:</strong> <span>+254 7147 45023</span></p> */}
          <p><strong>Email:</strong> <span>info@africanuncut-safaris.com</span></p>
          <p><strong>Email:</strong> <span>ben@africanuncut-safaris.com</span></p>
        </div>
      </div>

      <div className="col-lg-3 col-md-3 footer-links">
        <ul className="list-unstyled">
          <li><NavLink to="/" className="text-white">Home</NavLink></li>
          <li><NavLink to="/about" className="text-white">About us</NavLink></li>
          <li><NavLink to="" className="text-white">Kenya Tours</NavLink></li>
          <li><NavLink to="" className="text-white">Day Trips</NavLink></li>
          <li><NavLink to="" className="text-white">Contact Us</NavLink></li>
        </ul>
      </div>

      <div className="col-md-3">
        <h3 className="text-white">Follow Us</h3>
        <ul className="list-unstyled d-flex justify-content-between">
          <li><a href="https://m.facebook.com/events/734147165339474/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} size="2x" className="text-white" /></a></li>
          <li><a href="https://www.tiktok.com/@african.uncut.saf" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTiktok} size="2x" className="text-white" /></a></li>
          <li><a href="https://wa.me/25412603487?text=Hi, I got your WhatsApp information from your website." target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWhatsapp} size="2x" className="text-white" /></a></li>
          <li><a href="https://www.instagram.com/africanuncutsafaris2023/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} size="2x" className="text-white" /></a></li>
        </ul>

      </div>

      <div className="col-md-3">
        <img src={img11} alt="Africa Uncut Safaris Logo" style={{ height: '170px', marginLeft: '35px' }} />
      </div>
    </div>
  </div>

  <div className="container copyright text-center mt-4">
    <p>© <span>Copyright</span> <strong className="px-1 sitename">Africa Uncut Safaris</strong> <br /><span>All Rights Reserved</span></p>
  </div>
</footer>

      
  );
}

export default Footer;
