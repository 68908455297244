import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import { motion } from 'framer-motion';
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import CenterDiv from '../components/CenterDiv'

import vid1 from '../assets/img/vid.mp4';
import img1 from '../assets/img/up.jpg'; 
import img3 from '../assets/img/hellsg.jpg';
import img4 from '../assets/img/ele.jpg';
import img5 from '../assets/img/tsa.jpg';
import im1 from '../assets/img/1.jpeg'; 
import im3 from '../assets/img/3.jpeg'; 
import im4 from '../assets/img/4.jpeg'; 
import im5 from '../assets/img/5.jpeg'; 
import im7 from '../assets/img/7.jpeg'; 
import im9 from '../assets/img/20.jpeg'; 
import im10 from '../assets/img/10.jpeg'; 
import aboutImage from '../assets/img/gir.jpg'; 
import sunss from '../assets/img/sunss.jpg';
import girsam from '../assets/img/girsam.jpg';
import lewa from '../assets/img/lewa.jpg';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

function Home()  {

  const [knowsNeed, setKnowsNeed] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedDestination, setSelectedDestination] = useState('');
  const [filteredPackages, setFilteredPackages] = useState([]);

  const handleNeedChange = (e) => setKnowsNeed(e.target.value);
  const handleCountryChange = (e) => setSelectedCountry(e.target.value);
  const handleDestinationChange = (e) => setSelectedDestination(e.target.value);

  const handleFilter = () => {
    // Example filter logic - adjust based on your actual data
    const filtered = packages.filter(pkg =>
      (knowsNeed === '' || pkg.knownNeed === knowsNeed) &&
      (selectedCountry === '' || pkg.country === selectedCountry) &&
      (selectedDestination === '' || pkg.destination === selectedDestination)
    );
    setFilteredPackages(filtered);
  };

  const tourPackages = [
    { id: 1, title: 'Masai Mara Safari', image: img5, description: '3 Days Private Masai Mara Safari.', price: '$1200 per Person' },
    { id: 2, title: 'The Great Rift Valley Discovery', image: im1, description: '4 Days Masai Mara Nakuru Private Van Tour.', price: '$3000 per Person' },
    { id:18 , title: '3 days Private Budget tour', image: sunss, description:'Private budget tour in Amboseli.', price:'$700 per Person'},
    { id:21 , title: '3 days Amboseli Joining Tour ', image:lewa, description: 'Join a group safari to the Amboseli', price: '$600 per Person' },
    { id:25 , title: 'Luxury tour of Samburu.', image:girsam, description: '3 days luxury Samburu tour', price: '$900 per Person' },
    { id:5 , title: 'Private safari in a safari van', image:im5, description: '3 days private safari-van edition', price: '$1500 per Person' },

  ];

  const destinations = [
    { id: 1, name: 'Masai Mara', image: im4, packages: 5, country: 'Kenya' },
    { id: 4, name: 'Tsavo', image: img5, packages: 3, country: 'Kenya' },
    { id: 2, name: 'Mt Kenya', image: img1, packages: 3, country: 'Kenya' },
    { id: 3, name: 'Mt Kilimanjaro', image: im3, packages: 4, country: 'Tanzania' },
    { id: 5, name: 'Amboseli', image: im1, packages: 3, country: 'Kenya' },
    { id: 6, name: 'Group Joining', image: im9, packages: 5, country: 'Kenya' },
    { id: 7, name: 'North Frontier', image: im7, packages: 4, country: 'Kenya' },
    { id: 8, name: 'Day Tours and Excursions', image: im10, packages: 6, country: 'Kenya' },
    { id: 9, name: 'Kenya, Tanzania and Zanzibar combo', image: im4, packages: 6, country: 'Kenya' },
  ];

  const packages = [
    { id: 1, image: im1, title: 'Masai Mara Safari', description: '3 Days Private Masai Mara Safari.', knownNeed: 'yes', country: 'Kenya', destination: 'Masai Mara' },
    { id: 25, image: img3, title: 'Maasai Mara Adventure', description: '3 Days Private Luxury Samburu Tour.', knownNeed: 'no', country: 'Kenya', destination: 'Tsavo' },
    { id: 22, image: img4, title: 'The Great Rift Valley Discovery', description: '4 Days Masai Mara Nakuru Private Van Tour.', knownNeed: 'yes', country: 'Kenya', destination: 'Mt Kenya' },
  ];

  const testimonials = [
    { id: 1, name: 'Sofia Greece GR', visitDate: 'February 2024', feedback: 'The experience to ride and feel you are free next to the animals is unique!!!' },
    { id: 2, name: 'Simon UK', visitDate: 'September 2023', feedback: 'Adventurous and amazing. I travelled to the Maasai Mara with my family for 3 days.' },
    { id: 3, name: 'Emily Canada CA', visitDate: 'November 2023', feedback: 'Ben was amazing! Very professional, friendly and did fantastic job.' },
  ];
  
  




  return (
    <div className="home">
      {/* Hero Section */}
      <div className="hero">
        <video autoPlay loop muted className="hero-video">
          <source src={vid1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content text-center">
          <h1>Welcome to African Uncut Safaris</h1>
          <p>Experience the beauty and diversity of Africa with our expertly crafted tours.</p>
        </div>
      </div>

      {/* About Us Section */}
      <section className="about-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h2>About Us</h2>
              <h4>Discover the Heart of Africa with African Uncut Safaris</h4>
              <p>
              African Uncut Safaris is dedicated to providing unforgettable experiences
              across Africa. Our team of expert guides and travel planners ensure you
              have a safe, enjoyable, and enriching journey. 
              Based in Nairobi, African Uncut Safaris was founded by passionate adventurers and wildlife enthusiasts with a mission to offer authentic safari experiences that connect you with the African wilderness.
            
              </p>
            </div>
            <div className="col-md-6">
              <img src={aboutImage} alt="About Us" className="img-fluid about-image" />
            </div>
          </div>
        </div>
      </section>

      {/* Destinations Section */}
      <section className="destinations mt-5">
      <div className="container">
        <h2 className="text-center">Explore Our Destinations</h2>
        <div className="d-flex flex-wrap justify-content-center">
          {destinations.map((destination, index) => (
            <motion.div
              key={destination.id}
              className="destination-card m-2"
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Link to={`/destinations/${destination.id}`} className="card-link">
                <Card className="h-100">
                  <div className="card-image">
                    <Card.Img variant="top" src={destination.image} alt={destination.name} />
                    <div className="card-overlay">
                      <Card.Title>{destination.name}</Card.Title>
                      <div className="card-packages">({destination.packages}) Packages</div>
                    </div>
                  </div>
                </Card>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </section>

      {/* Call to Action Section */}
      <section className="call-to-action mt-5 text-center">
      <h2>Ready for an Unforgettable Adventure?</h2>
      <p>Book your tour with us today and explore the wonders of Africa.</p>
      <a href="https://wa.me/25412603487?text=Hi, I got your WhatsApp information from your website." className="btn btn-success btn-lg" role="button">Send Whatsapp Message</a>
      </section>

     {/* Filter Section */}
<section className="filter-section py-5 text-center">
  <h2>Find Your Perfect Package</h2>
  <div>
    <select value={knowsNeed} onChange={handleNeedChange}>
      <option value="">Select Need</option>
      <option value="yes">I know my need</option>
      <option value="no">I am unsure</option>
    </select>
    <select value={selectedCountry} onChange={handleCountryChange}>
      <option value="">Select Country</option>
      <option value="Kenya">Kenya</option>
      <option value="Tanzania">Tanzania</option>
    </select>
    <select value={selectedDestination} onChange={handleDestinationChange}>
      <option value="">Select Destination</option>
      {destinations.map(dest => (
        <option key={dest.id} value={dest.name}>{dest.name}</option>
      ))}
    </select>
    <button onClick={handleFilter} className="btn btn-success btn-lg">Filter Packages</button>
  </div>
  <div className="container">
    <div className="row">
      {filteredPackages.length > 0 ? (
        filteredPackages.map(pkg => (
          <div key={pkg.id} className="col-md-4 mb-4">
            <Card className="package-card h-100">
              <Card.Img variant="top" src={pkg.image} alt={pkg.title} />
              <Card.Body>
                <Card.Title>{pkg.title}</Card.Title>
                <Card.Text>{pkg.description}</Card.Text>
                <Card.Text>{pkg.price}</Card.Text>
              </Card.Body>
              <Link to={`/package/${pkg.id}`} className="btn btn-success">
                View Details
              </Link>
            </Card>
          </div>
        ))
      ) : (
        <div className="col-12 text-center mt-4">
          <p>No packages found. Try adjusting your filters.</p>
          <Link to="/tours" className="btn btn-success">
            View All Tours
          </Link>
        </div>
      )}
    </div>
  </div>
</section>



        {/* Tour Packages Section */}
        <section className="tour-packages mt-5">
        <div className="container">
          <h2 className="text-center">Our Top Packages</h2>
          <div className="row">
            {tourPackages.map((packageItem) => (
              <motion.div
                key={packageItem.id}
                className="col-md-4 mb-4"
                whileHover={{ scale: 1.05 }}
              >
                <Card>
                  <Card.Img variant="top" src={packageItem.image} alt={packageItem.title} />
                  <Card.Body>
                    <Card.Title>{packageItem.title}</Card.Title>
                    <Card.Text>{packageItem.description}</Card.Text>
                    <Link to={`/package/${packageItem.id}`} className="btn btn-success">
                   View Details
                    </Link>
                    </Card.Body>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      
      <CenterDiv />

      {/* Testimonials Section */}
      <section className="testimonials py-5">
        <div className="container">
          <div className="section-title text-center">
            <h2>What Our Guests Say</h2>
          </div>
          <div className="row">
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="col-md-4">
                <div className="testimonial-card">
                  <p>{testimonial.feedback}</p>
                  <h5>{testimonial.name}</h5>
                  <small>{testimonial.visitDate}</small>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
