import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/img/logo.png';

function Header() {
  return (
    <header className="header">
      <div className="top-bar">
        <div className="contact-info">
          <span>Email: info@africanuncut-safaris.com</span>
        </div>
        <div className="logo">
          <img src={logo} alt="Africa Uncut Tours Logo" /> 
        </div>
        <div className="contact-info">
        <span>Phone:
          <a href="tel:+254712603487" className="text-white-500 hover:underline">
            +254 712 603487
          </a>
        </span>   
       </div>
      </div>
      <nav className="main-nav">
      <h3 className="contact-info"> <span> 
        African Uncut Safaris </span> 
         </h3>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/tours">Tours</Link></li>
          <li> <Link to ="/blogs" > Blogs </Link> </li>

          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
